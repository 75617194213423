import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Assets/imgs/logo.webp";
import bannerImg from "../Assets/imgs/hero-img.webp";
import bima from "../Assets/imgs/bima.webp";
import waada from "../Assets/imgs/waada.webp";
import efu from "../Assets/imgs/efu.webp";
import mediqIcon from "../Assets/imgs/mediqIcon.webp";
import webdoc from "../Assets/imgs/webdocicon.webp";
import icon1 from "../Assets/imgs/icons-1.webp";
import icontwo from "../Assets/imgs/icon-2.webp";
import iconthree from "../Assets/imgs/icon-3.webp";
import iconfour from "../Assets/imgs/icon-4.webp";
import iconfive from "../Assets/imgs/icon-5.webp";
import iconsix from "../Assets/imgs/icon-6.webp";
import iconseven from "../Assets/imgs/icon-7.webp";
import iconeight from "../Assets/imgs/icon-8.webp";
import claim from "../Assets/imgs/calim-img.webp";
import tick from "../Assets/imgs/check-icon.webp";
import buypolicy from "../Assets/imgs/buy-policy.webp";
import downloadImg from "../Assets/imgs/download-adpp.webp";
import downloadImgmob from "../Assets/imgs/download-mb.webp";
import facebook from "../Assets/imgs/fb-icon.webp";
import insta from "../Assets/imgs/insta-icon.webp";
import Dropdown from "react-bootstrap/Dropdown";
import profileVector from "../Assets/imgs/profileVector.webp";
import logouticon from "../Assets/imgs/logouticon.webp";
import userAvatar from "../Assets/imgs/user-avatar.webp";
// import linkdin from "../Assets/imgs/linkedin-icon.webp";
// import twitter from "../Assets/imgs/twitter-icon.webp";
import leftside from "../Assets/imgs/leftside-bg.webp";
import menubar from "../Assets/imgs/menubar.webp";
import closebtn from "../Assets/imgs/cross-icon.webp";
// modal
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import popUpRightImg from "../Assets/imgs/popup-bg.webp";
import popUpLogo from "../Assets/imgs/popup-img.webp";
// modal

import mbima from "../Assets/imgs/modallogos/BIMA.webp";
import mefu from "../Assets/imgs/modallogos/efu.webp";
import mwaada from "../Assets/imgs/modallogos/Waada.webp";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
// swiper

// Import Pop Up Modals Over here
import InsuranceDetail from "./popups/InsuranceDetail";
import InsuranceProvider from "./popups/insurranceProvider";
import InsurranceSlider from "./popups/InsurranceSlider";
import UserVerificationPopUp from "./popups/UserVerificationPopUp";
import UserProfilePopup from "./popups/UserProfilePopup";
import UserDetailsPopup from "./popups/UserDetailsPopup";
import UserPaymentPopup from "./popups/UserPaymentPopup";
import UserPaymentSummary from "./popups/UserPaymentSummary";
import UserSuccesPopup from "./popups/UserSuccesPopup";
import PurchaseOTPPopUp from "./popups/PurchaseOTPPopUp";
import { imageUrl } from "../utils/Config";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import EventsName from "../constants/EventsName";
import testimonialIcon1 from "../Assets/imgs/testminial_icon_1.webp";
import testimonialIcon2 from "../Assets/imgs/testminial_icon_2.webp";
import testimonialImg1 from "../Assets/imgs/testimonial_img_1.webp";
import testimonialImg2 from "../Assets/imgs/testimonial_img_2.webp";
import testimonialImg3 from "../Assets/imgs/testimonial_img_3.webp";
import testimonialImg4 from "../Assets/imgs/testimonial_img_4.webp";
import testimonialImg5 from "../Assets/imgs/testimonial_img_5.webp";

// import { Accordion } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import cutomerLogo1 from "../Assets/imgs/cutomer-logo/partner_s_logo-1.webp";
import cutomerLogo2 from "../Assets/imgs/cutomer-logo/partner_s_logo-2.webp";
import cutomerLogo3 from "../Assets/imgs/cutomer-logo/partner_s_logo-3.webp";
import cutomerLogo4 from "../Assets/imgs/cutomer-logo/partner_s_logo-4.webp";
import cutomerLogo5 from "../Assets/imgs/cutomer-logo/partner_s_logo-5.webp";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Header from "./common/Header";
import Footer from "./common/Footer";

interface HomeScreenProps {
  insuranceCategories: any;
  partnersList: any;
  partnerPolicies: any;
  policyDetails: any;
  onCategoryPressed: (id: number, category: any) => void;
  onPartnerListPressed: (partnerID: number, partnerData: any) => void;
  onPartnerPolicyPressed: (policy_id: number, policyTitle: string) => void;
  isPartnersListModalVisible: boolean;
  isPartnerPoliciesModalVisible: boolean;
  isPolicyDetailsModalVisible: boolean;
  isUserVerificationModalVisible: boolean;
  isUserProfileModalVisible: boolean;
  isUserDetailsModalVisible: boolean;
  onHandleClosePartnersListModal: () => void;
  onHandleClosePartnerPolciesModal: () => void;
  onHandleClosePolicyDetailsModal: () => void;
  onHandleCloseUserDetailsModal: () => void;
  onHandleCloseUserVerificationModal: () => void;
  onHandleCloseUserProfileModal: () => void;
  onHandleSelectCover: (coverId: number) => void;
  msisdn: string;
  otp: string[];
  isOTPSent: boolean;
  handleMsisdnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnPressSendOTPButton: () => void;
  handleOTPSubmit: () => void;
  inputRefs: any;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  showResendButton: boolean;
  timer: number;
  onHandleSetUpUserProfile: (data: any) => void;
  isLoading: boolean;
  showDownloadPopup: boolean;
  onShowDownloadPopup: () => void;
  onHideDownloadPopup: () => void;
  showPaymentPlanPopup: boolean;
  onHandleClosePaymentPlanPopup: () => void;
  isLoggedIn: boolean;
  userId: any;
  onNavigateToLanding: () => void;
  onLogout: () => void;
  isNominee: boolean;
  isBeneficiary: boolean;
  isMobilePolicy: boolean;
  isPurchased: boolean;
  activeTab: string;
  handleUpdateActiveTab: (tab: string) => void;
  handleTogglePersonal: () => void;
  isPersonalOpen: boolean;
  handleToggleNominee: () => void;
  isNomineeOpen: boolean;
  noOfBeneficiaries: number;
  handleUpdateNoOfBeneficiaries: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  expandedStates: any;
  toggleExpandedState: (i: any) => void;
  options: any;
  handleUpdateBeneficiariesData: (data: any) => void;
  handleUpdateNomineeData: (data: any) => void;
  relationsData: any;
  personalData: any;
  // onHandleSubmitUserDetailsPressed: (
  //   policyData: any,
  //   beneficiariesData: any
  // ) => void;
  handleUpdatePaymentMethod: (method: string) => void;
  paymentPlan: string;
  onHandleProceedToPayment: () => void;
  onHandleClosePurchaseOTPPopUp: () => void;
  showPaymentOtpPopup: boolean;
  onHandlePressVerifyButton: () => void;
  showSuccessPopUp: boolean;
  handleCloseSuccessPopUp: () => void;
  onHandleNavigateToProfileScreen: () => void;
  handleRadioChange: (
    coverId: number,
    coverDetails: any,
    policyDetails: any
  ) => void;
  handleSelectCover: () => void;
  handleUpdateConsent: () => void;
  selectedCoverId: any;
  isConsentChecked: boolean;
  errorMsg: string;
  isPrepaid: boolean;
  policyPlans: any;
  isPaymentSummaryModalVisible: boolean;
  onHandleClosePaymentSummaryPopUp: () => void;
  selectedPolicy: any;
  selectedPlan: any;
  _msisdn: any;
  selectedCategory: any;
  selectedPartner: any;
  sendPurchaseOtp: () => void;
  handleNomineeInputChange: any;
  handleInputChange: any;
  errors: any;
  onSaveDataPressed: () => void;
  beneficiariesData: any;
  nomineeData: any;
  newUserData: any;
  handleNewUserInputChange: any;
  handleSaveUserData: () => void;
  isOTPSending: boolean;
  handleToggleMobile: () => void;
  isMobileOpen: boolean;
  handleDeviceInfoInputChange: (field: string, value: string) => void;
  mobileData: any;
  paymentMethod: string;
}

const Home = (props: HomeScreenProps) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Download App modal */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        {/* <Modal.Body> */}
        <div className="modal-bg">
          <div className="modal-left-content">
            <div className="popup-logo-img">
              <img src={logo} alt="" width={113} height={77} />
            </div>
            <div className="main-heading">Download Now!</div>
            <div className="main-content">
              Discover the convenience and peace of mind of our efficient policy
              management and rapid claims processing, delivering seamless
              insurance services, no matter the time or place.
              <div className="popup-arrow-image">
                {/* <img
                    src={popUpArrow}
                    alt="popup-arrow"
                    width={100}
                    height={107}
                  /> */}
              </div>
            </div>
            <Link
              to="https://onelink.to/cg7nvr"
              target="_blank"
              className="d-default"
            >
              <span>DOWNLOAD APP NOW </span>
            </Link>
          </div>
          <div className="pop-up-right-image">
            <img src={popUpLogo} alt="" width={450} height={324} />
          </div>
          <div className="close-modal-button">
            <div onClick={handleClose}>
              <img
                src={closebtn}
                alt="cross-popup-btn"
                width={25}
                height={25}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Insurance Provider Modal */}
      <InsuranceProvider
        partnersList={props.partnersList}
        isPartnersListModalVisible={props.isPartnersListModalVisible}
        onHandleClosePartnersListModal={props.onHandleClosePartnersListModal}
        onPartnerListPressed={props.onPartnerListPressed}
        isLoading={props.isLoading}
      />

      {/* Insurrance Slider Modal */}
      <InsurranceSlider
        partnerPolicies={props.partnerPolicies}
        isPartnerPoliciesModalVisible={props.isPartnerPoliciesModalVisible}
        onHandleClosePartnerPolciesModal={
          props.onHandleClosePartnerPolciesModal
        }
        onPartnerPolicyPressed={props.onPartnerPolicyPressed}
        selectedPartner={props.selectedPartner}
        isLoading={props.isLoading}
      />

      {/* Insurance Detail Modal */}
      <InsuranceDetail
        policyDetails={props.policyDetails}
        isPolicyDetailsModalVisible={props.isPolicyDetailsModalVisible}
        onHandleClosePolicyDetailsModal={props.onHandleClosePolicyDetailsModal}
        onHandleSelectCover={props.onHandleSelectCover}
        handleRadioChange={props.handleRadioChange}
        handleSelectCover={props.handleSelectCover}
        handleUpdateConsent={props.handleUpdateConsent}
        selectedCoverId={props.selectedCoverId}
        isConsentChecked={props.isConsentChecked}
        errorMsg={props.errorMsg}
        isPrepaid={props.isPrepaid}
        policyPlans={props.policyPlans}
        isLoading={props.isLoading}
      />

      {/* User verification Modal */}
      <UserVerificationPopUp
        isUserVerificationModalVisible={props.isUserVerificationModalVisible}
        onHandleCloseUserVerificationModal={
          props.onHandleCloseUserVerificationModal
        }
        msisdn={props.msisdn}
        isOTPSent={props.isOTPSent}
        handleMsisdnChange={props.handleMsisdnChange}
        handleOnPressSendOTPButton={props.handleOnPressSendOTPButton}
        handleOTPSubmit={props.handleOTPSubmit}
        otp={props.otp}
        inputRefs={props.inputRefs}
        handlePINInputChange={props.handlePINInputChange}
        handlePINInput={props.handlePINInput}
        showResendButton={props.showResendButton}
        timer={props.timer}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
      />

      {/* User Profile Modal */}
      <UserProfilePopup
        isUserProfileModalVisible={props.isUserProfileModalVisible}
        onHandleCloseUserProfileModal={props.onHandleCloseUserProfileModal}
        onHandleSetUpUserProfile={props.onHandleSetUpUserProfile}
        newUserData={props.newUserData}
        handleNewUserInputChange={props.handleNewUserInputChange}
        handleSaveUserData={props.handleSaveUserData}
        errorMsg={props.errorMsg}
        isLoading={props.isLoading}
      />

      {/* User Details Modal */}
      <UserDetailsPopup
        isUserDetailsModalVisible={props.isUserDetailsModalVisible}
        onHandleCloseUserDetailsModal={props.onHandleCloseUserDetailsModal}
        isNominee={props.isNominee}
        isBeneficiary={props.isBeneficiary}
        isMobilePolicy={props.isMobilePolicy}
        isPurchased={props.isPurchased}
        activeTab={props.activeTab}
        handleUpdateActiveTab={props.handleUpdateActiveTab}
        handleTogglePersonal={props.handleTogglePersonal}
        isPersonalOpen={props.isPersonalOpen}
        handleToggleNominee={props.handleToggleNominee}
        isNomineeOpen={props.isNomineeOpen}
        noOfBeneficiaries={props.noOfBeneficiaries}
        handleUpdateNoOfBeneficiaries={props.handleUpdateNoOfBeneficiaries}
        expandedStates={props.expandedStates}
        toggleExpandedState={props.toggleExpandedState}
        options={props.options}
        handleUpdateBeneficiariesData={props.handleUpdateBeneficiariesData}
        handleUpdateNomineeData={props.handleUpdateNomineeData}
        relationsData={props.relationsData}
        personalData={props.personalData}
        msisdn={props.msisdn}
        // onHandleSubmitUserDetailsPressed={
        //   props.onHandleSubmitUserDetailsPressed
        // }
        errorMsg={props.errorMsg}
        handleNomineeInputChange={props.handleNomineeInputChange}
        handleInputChange={props.handleInputChange}
        errors={props.errors}
        onSaveDataPressed={props.onSaveDataPressed}
        beneficiariesData={props.beneficiariesData}
        nomineeData={props.nomineeData}
        handleToggleMobile={props.handleToggleMobile}
        isMobileOpen={props.isMobileOpen}
        handleDeviceInfoInputChange={props.handleDeviceInfoInputChange}
        mobileData={props.mobileData}
      />

      {/* User Payment Modal */}
      <UserPaymentPopup
        showPaymentPlanPopup={props.showPaymentPlanPopup}
        onHandleClosePaymentPlanPopup={props.onHandleClosePaymentPlanPopup}
        handleUpdatePaymentMethod={props.handleUpdatePaymentMethod}
        paymentPlan={props.paymentPlan}
        onHandleProceedToPayment={props.onHandleProceedToPayment}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
        paymentMethod={props.paymentMethod}
      />

      <PurchaseOTPPopUp
        onHandleClosePurchaseOTPPopUp={props.onHandleClosePurchaseOTPPopUp}
        showPaymentOtpPopup={props.showPaymentOtpPopup}
        otp={props.otp}
        inputRefs={props.inputRefs}
        handlePINInputChange={props.handlePINInputChange}
        handlePINInput={props.handlePINInput}
        showResendButton={props.showResendButton}
        timer={props.timer}
        onHandleProceedToPayment={props.onHandleProceedToPayment}
        onHandlePressVerifyButton={props.onHandlePressVerifyButton}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
        sendPurchaseOtp={props.sendPurchaseOtp}
        isOTPSending={props.isOTPSending}
      />

      {/* User Payment Summary Modal */}
      <UserPaymentSummary
        isPaymentSummaryModalVisible={props.isPaymentSummaryModalVisible}
        onHandleClosePaymentSummaryPopUp={
          props.onHandleClosePaymentSummaryPopUp
        }
        selectedPolicy={props.selectedPolicy}
        selectedPlan={props.selectedPlan}
        _msisdn={props._msisdn}
        selectedCategory={props.selectedCategory}
        sendPurchaseOtp={props.sendPurchaseOtp}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
        paymentPlan={props.paymentPlan}
        // selectedPartner={selectedPartner}
      />

      {/* User Success Modal */}
      <UserSuccesPopup
        showSuccessPopUp={props.showSuccessPopUp}
        handleCloseSuccessPopUp={props.handleCloseSuccessPopUp}
      />

      {/* header */}
      <Header
        userId={props.userId}
        onNavigateToLanding={props.onNavigateToLanding}
        personalData={props.personalData}
        onHandleNavigateToProfileScreen={props.onHandleNavigateToProfileScreen}
        onLogout={props.onLogout}
      />
      <div className="banner-section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner-heading">
                Your Trusted
                <br /> <span>Insurance</span> Partner
              </div>
              <div className="banner-description">
                Discover convenience and peace of mind at your fingertips with
                our efficient policy management and quick claims processing,
                delivering seamless insurance services anytime, anywhere.
              </div>
              <div className="d-btn banner-btn">
                <a
                  href="https://onelink.to/cg7nvr"
                  target="_blank"
                  // onClick={() => {
                  //   tagManagerEvents(EventsName.DownloadAppButton, "");
                  //   handleShow();
                  // }}
                >
                  Download app now{" "}
                </a>
              </div>
              {/* <div className="satisfaction-section dis-desk">
                <div className="sat-box">
                  <div className="_tnum">12K+</div>
                  <p>Downloads</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">10K+</div>
                  <p>Satisfied Customers</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">2K+</div>
                  <p>Insurance Claims</p>
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="banner-img">
                <img src={bannerImg} alt="banner" width={546} height={511} />
              </div>
              {/* <div className="satisfaction-section dis-mobile">
                <div className="sat-box">
                  <div className="_tnum">12K+</div>
                  <p>Downloads</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">10K+</div>
                  <p>Satisfied Customers</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">2K+</div>
                  <p>Insurance Claims</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="trusted-customer main-padding"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="trusted-text">Our Trusted Partners</div>
          <div className="logo-wraper">
            <div className="__customerIcon">
              <img src={bima} alt="bima" width={150} height={60} />
            </div>
            <div className="__customerIcon">
              <img src={waada} alt="waada" width={150} height={60} />
            </div>
            <div className="__customerIcon">
              <img src={efu} alt="efu" width={150} height={60} />
            </div>
            <div className="__customerIcon">
              <img src={webdoc} alt="efu" width={150} height={60} />
            </div>
            <div className="__customerIcon">
              <img src={mediqIcon} alt="efu" width={150} height={60} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="offer-insurence"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="tc-abs-img">
          <img src={leftside} alt="left side" width={350} height={749} />
        </div>
        <div className="container">
          <div className="main-heading">
            We Offer Different Insurances To Improve <br /> Your Life
          </div>
          <div className="offer-swiper">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
            >
              {props.insuranceCategories.map(
                (category: any, index: any) =>
                  index % 2 === 0 && (
                    <SwiperSlide>
                      {index < props.insuranceCategories.length && (
                        <div
                          key={category.id}
                          className="swiper-card"
                          onClick={() => {
                            props.onCategoryPressed(
                              props.insuranceCategories[index].id,
                              category
                            );
                            tagManagerEvents(EventsName.productClick, "");
                          }}
                        >
                          <img
                            src={category.icon}
                            alt={category.title}
                            width={136}
                            height={80}
                          />
                          <h3>{props.insuranceCategories[index].title}</h3>
                          <p>{props.insuranceCategories[index].description}</p>
                        </div>
                      )}
                      {index + 1 < props.insuranceCategories.length && (
                        <div
                          key={category.id}
                          className="swiper-card"
                          onClick={() =>
                            props.onCategoryPressed(
                              props.insuranceCategories[index + 1].id,
                              category
                            )
                          }
                        >
                          <img
                            src={props.insuranceCategories[index + 1].icon}
                            alt={props.insuranceCategories[index + 1].title}
                            width={136}
                            height={80}
                          />
                          <h3>{props.insuranceCategories[index + 1].title}</h3>
                          <p>
                            {props.insuranceCategories[index + 1].description}
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="claim-section main-padding" id="insurance">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src={claim}
                alt="claim"
                className="claimImg"
                width={340}
                height={380}
                data-aos="fade-right"
                data-aos-duration="1500"
              />
            </div>
            <div className="col-md-7">
              <div className="ml" data-aos="fade-left" data-aos-duration="1500">
                <div className="main-heading">CLAIMS</div>
                <div className="claim-desc">
                  Insurance Claims through FikrFree makes it convenient and
                  efficient for policyholders to submit and track claims,
                  ensuring quick resolutions and peace of mind.
                </div>
                <div>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    Real-time updates.
                  </p>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    User-friendly.
                  </p>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    Seamless experience.
                  </p>
                </div>
                <div className="claim_text_btn">
                  <p>To make a claim download FikrFree app</p>
                  <div className="d-btn">
                    <a href="https://onelink.to/cg7nvr" target="_blank">
                      Download App{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CUSTOMER-TESTIMONIALS-SECTION */}

      <div
        id="claims"
        className="testimonial-section main-padding"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="main-heading">Customer Testimonials</div>
            <div className="offer-swiper testimonial_swipper">
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="testimonial_box">
                    <div className="testimonial_card">
                      <div className="test_content">
                        The variety of insurance plans and seamless doctor
                        consultations make this app a must-have for my family!
                      </div>
                      <div className="test_img_text">
                        <div className="test_img">
                          <img src={testimonialImg1} alt="testimonial_image" />
                        </div>
                        <div className="test_name">Haseeb Qasim</div>
                      </div>
                    </div>
                    <div className="testimonial_icon">
                      <img src={testimonialIcon1} alt="testimonial_icon" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial_box">
                    <div className="testimonial_card">
                      <div className="test_content">
                        Effortlessly manage health insurance, access pharmacies,
                        and consult doctors—all in one user-friendly app. Highly
                        recommended!
                      </div>
                      <div className="test_img_text">
                        <div className="test_img">
                          <img src={testimonialImg2} alt="testimonial_image" />
                        </div>
                        <div className="test_name">Neha Fatima</div>
                      </div>
                    </div>
                    <div className="testimonial_icon">
                      <img src={testimonialIcon2} alt="testimonial_icon" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial_box">
                    <div className="testimonial_card">
                      <div className="test_content">
                        From tailored insurance plans to quick pharmacy orders,
                        this app simplifies health management like never before!
                      </div>
                      <div className="test_img_text">
                        <div className="test_img">
                          <img src={testimonialImg3} alt="testimonial_image" />
                        </div>
                        <div className="test_name">Zulqarnain Zafar</div>
                      </div>
                    </div>
                    <div className="testimonial_icon">
                      <img src={testimonialIcon1} alt="testimonial_icon" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial_box">
                    <div className="testimonial_card">
                      <div className="test_content">
                        I love the variety of insurance options and the
                        convenience of asking doctors anytime. Highly reliable!"
                      </div>
                      <div className="test_img_text">
                        <div className="test_img">
                          <img src={testimonialImg4} alt="testimonial_image" />
                        </div>
                        <div className="test_name">Osama Sajjad</div>
                      </div>
                    </div>
                    <div className="testimonial_icon">
                      <img src={testimonialIcon2} alt="testimonial_icon" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="testimonial_box">
                    <div className="testimonial_card">
                      <div className="test_content">
                        A game-changer! Comprehensive insurance options paired
                        with seamless pharmacy and doctor access it's everything
                        I ever needed.
                      </div>
                      <div className="test_img_text">
                        <div className="test_img">
                          <img src={testimonialImg5} alt="testimonial_image" />
                        </div>
                        <div className="test_name">Anum Fatima</div>
                      </div>
                    </div>
                    <div className="testimonial_icon">
                      <img src={testimonialIcon1} alt="testimonial_icon" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* CUSTOMER-TESTIMONIALS-SECTION-END */}

      {/* Faq-Section */}
      <div className="faq_section main-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-heading">Frequently Asked Questions</div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="accordian_container">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is Fikrfree</Accordion.Header>
                    <Accordion.Body>
                      FikrFree is an insurance and healthcare marketplace app
                      offering a variety of insurance products and healthcare
                      services, including e-pharmacy and doctor consultations.
                      You can activate, deactivate, and manage your policies
                      conveniently through the app.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                       Where can I download the FikrFree app?
                    </Accordion.Header>
                    <Accordion.Body>
                      FikrFree is an insurance and healthcare marketplace app
                      offering a variety of insurance products and healthcare
                      services, including e-pharmacy and doctor consultations.
                      You can activate, deactivate, and manage your policies
                      conveniently through the app.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Does FikrFree have a website?
                    </Accordion.Header>
                    <Accordion.Body>
                      FikrFree is an insurance and healthcare marketplace app
                      offering a variety of insurance products and healthcare
                      services, including e-pharmacy and doctor consultations.
                      You can activate, deactivate, and manage your policies
                      conveniently through the app.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Which types of insurance is available on FikrFree?
                    </Accordion.Header>
                    <Accordion.Body>
                      FikrFree is an insurance and healthcare marketplace app
                      offering a variety of insurance products and healthcare
                      services, including e-pharmacy and doctor consultations.
                      You can activate, deactivate, and manage your policies
                      conveniently through the app.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="d-btn" id="faqs">
                  <a href="/faq">View More</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={buypolicy}
                alt="buy policy"
                className="buypolicymainImg"
                width={546}
                height={600}
                data-aos="fade-left"
                data-aos-duration="1500"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Faq-Section-End */}

      {/* Customer-Support-section */}
      <div
        className="contact_section main-padding"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="main-heading">Customer Support</div>
              <div className="contact_content">
                Contact us at: info@fikrfree.com.pk
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="contact_box">
                <div className="contact_icon_img">
                  <img src={cutomerLogo1} alt="cutomer_logo_bima" />
                </div>
                <div className="contact_text">
                  <div className="c_name">BIMA</div>

                  <div className="helpl_text">Helpline</div>
                  <div className="helpl_number">042111119878</div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="contact_box">
                <div className="contact_icon_img">
                  <img src={cutomerLogo2} alt="customer_logo_wada" />
                </div>
                <div className="contact_text">
                  <div className="c_name">WAADA</div>

                  <div className="helpl_text">Helpline</div>
                  <div className="helpl_number">021111992232</div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="contact_box">
                <div className="contact_icon_img">
                  <img src={cutomerLogo3} alt="cutomer_logo_efu" />
                </div>
                <div className="contact_text">
                  <div className="c_name">EFU</div>

                  <div className="helpl_text">Helpline</div>
                  <div className="helpl_number">042111333033</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="col-lg-4">
              <div className="contact_box">
                <div className="contact_icon_img">
                  <img src={cutomerLogo4} alt="cutomer_logo_webdoc" />
                </div>
                <div className="contact_text">
                  <div className="c_name">WEBDOC</div>

                  <div className="helpl_text">Helpline</div>
                  <div className="helpl_number">7030</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact_box">
                <div className="contact_icon_img">
                  <img src={cutomerLogo5} alt="cutomer_logo_mediq" />
                </div>
                <div className="contact_text">
                  <div className="c_name">Mediq</div>

                  <div className="helpl_text">Helpline</div>
                  <div className="helpl_number">+9251111377377</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Customer-Support-end */}
      <div className="downloadnow" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="dowmload-wraper">
              <div>
                <img
                  src={downloadImg}
                  alt="download"
                  className="dis-desk"
                  width={1116}
                  height={352}
                />
                <img
                  src={downloadImgmob}
                  alt="download"
                  className="dis-mobile"
                  width={324}
                  height={282}
                />
              </div>
              <div className="download-abs-content">
                <div className="main-heading">
                  Download
                  <img
                    src={logo}
                    alt="logo"
                    className="downloadlogo"
                    width={80}
                    height={55}
                  />
                  now
                </div>
                <div className="download-content">
                  Ensure peace of mind with FikrFree! Secure your insurance
                  needs today for a worry-free future.
                </div>
                <div className="d-btn">
                  <a href="https://onelink.to/cg7nvr" target="_blank">
                    Download APP
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className={`overlay ${isMobileMenuOpen ? "show" : ""}`}
        onClick={toggleMobileMenu}
      ></div>
    </>
  );
};

export default Home;
