export const ApiNames = {
  generateToken: "generateToken",
  insuranceCategories: "categories",
  checkOperator: "checkOperator",
  sendOTP: "sendOtp",
  checkUser: "user/checkUser",
  subscribeUser: "subscribe",
  loginUser: "login",
  partnersList: "partners/cat/",
  partnerPolicies: "policy/catPolicies/",
  policyDetails: "policy/details",
  userProfile: "user/profile",
  updateProfile: "user/updateProfile",
  relations: "relations",
  sendPurchaseOtp: "policy/sendPurchaseOtp", //this
  purchasePolicy: "policy/purchase", //this
  uploadProfilePicture: "uploadProfilePic",
  waadaSendOtp: "waada/sendOtp",
  waadaActivateMobileInsurance: "waada/activateMobileInsurance",
  bimaActivateHealthInsurance: "bima/activateHealthInsurance",
  bimaSendOtp: "bima/sendOtp",
  getFlow: "getMetaDataAndFlow",
  subscribe: "activateLPPolicy",
  sendLPOtp: "sendLPOtp",
  utmList: "utmList",
  headerEnrichment: "fetchHeMsisdn",
  plans: "plan",
  bimaActivatePlan: "bima/activatePlan",
};
