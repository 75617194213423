import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../styles/UserPaymentSummary.css";
import paymentImg1 from "../../Assets/imgs/payment-method-popup-img/payment-img-1.webp";
import summaryBimaImg from "../../Assets/imgs/summary-bima-icon.webp";
import "../../styles/insuranceInfoPopup.css";

interface UserPaymentSummaryProps {
  isPaymentSummaryModalVisible: boolean;
  onHandleClosePaymentSummaryPopUp: () => void;
  selectedPolicy: any;
  selectedPlan: any;
  _msisdn: any;
  selectedCategory: any;
  sendPurchaseOtp: () => void;
  isLoading: boolean;
  errorMsg: string;
  paymentPlan: string;
}

const createMarkup = (data: string) => {
  return { __html: data };
};

// Rename insurranceProvider to InsuranceProvider
const UserPaymentSummary = (props: UserPaymentSummaryProps) => {
  console.log("props.selectedPlan.type===>", props.selectedPlan.type);
  return (
    <>
      {/* USER-PAYMENT-SUMMARY-MODAl */}
      {/* <button onClick={handleShow}>Payment Summary</button> */}
      <Modal
        show={props.isPaymentSummaryModalVisible}
        onHide={props.onHandleClosePaymentSummaryPopUp}
        className="payment-summary-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Summary </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-summary-content">
            <div className="summmary-bima d-sum-gradient">
              <div className="summary-bima-img">
                <img
                  src={props.selectedCategory.icon}
                  alt=""
                  width={32}
                  height={33}
                />
              </div>
              <div className="bima-text summary-heading">
                {props.selectedPolicy.title}
              </div>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  props.selectedPolicy.description
                )}
                className="bima-content"
              ></div>
            </div>
            <div className="transaction-summary  d-sum-gradient">
              <div className="transcation-img-text">
                <div className="transaction-img">
                  <img src={paymentImg1} alt="" width={57} height={57} />
                </div>
                <div className="transaction-text">
                  <div className="trans-text">Payment Method</div>
                  <div className="trans-acc-name">Jazz Cash</div>
                </div>
              </div>
              <div className="account-detail">
                <div className="acc-text">Account No.</div>
                <div className="acc-number">0{props._msisdn}</div>
              </div>
            </div>
            <div className="transaction-details d-sum-gradient pading-bot">
              <div className="bronze-text summary-heading">
                {props.selectedPlan.title}
              </div>
              <div className="daily-charges">
                <div className="charges-text summary-heading">
                  {props.selectedPlan.type?.toLowerCase() === "daily"
                    ? "Daily Charges"
                    : props.selectedPlan.type?.toLowerCase() === "weekly"
                    ? "Weekly Charges"
                    : "Monthly Charges"}
                </div>
                <div className="ammount">
                  <span>{props.selectedPlan.charging}</span>Rs.
                </div>
              </div>
              {props.selectedPlan.type?.toLowerCase() === "daily" && (
                <div className="daily-charges">
                  <div className="charges-text summary-heading s-h-color">
                    x 30 days
                  </div>
                  <div className="ammount">
                    <span>{props.selectedPlan.charging * 30}</span>Rs.
                  </div>
                </div>
              )}
              {/* <div className="daily-charges tax">
                <div className="tax-charges">
                  <div className="charges-text summary-heading">Tax</div>
                  <div className="tax-text s-h-color">2.32%</div>
                </div>
                <div className="ammount">
                  <span>0.83</span>Rs.
                </div>
              </div> */}
            </div>
            <div className="total-amount d-sum-gradient total-am">
              <div className="daily-charges">
                <div className="charges-text summary-heading">Total Amount</div>
                <div className="ammount">
                  <span>
                    {props.selectedPlan.type?.toLowerCase() === "daily"
                      ? props.selectedPlan.charging * 30
                      : props.selectedPlan.charging}
                  </span>
                  Rs.
                </div>
              </div>
            </div>
          </div>
          {props.isLoading ? (
            <div className="d-btn">
              {/* {props.paymentPlan === "jazzcash" ? (
                <button>Proceeding</button>
              ) : ( */}
              <button>Sending OTP</button>
              {/* )} */}
            </div>
          ) : (
            <div className="d-btn">
              <button onClick={props.sendPurchaseOtp}>Next</button>
            </div>
          )}
          <div className="d-btn">
            <button
              onClick={props.onHandleClosePaymentSummaryPopUp}
              className="d-button"
            >
              Cancel
            </button>
          </div>
          {props.errorMsg && <p className="error-msg">{props.errorMsg}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserPaymentSummary;
