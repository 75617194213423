import React from "react";
import "../styles/Home.css";
import Header from "./common/Header";
import Footer from "./common/Footer";

const FaqPage = (props: any) => {
  return (
    <>
   <Header
        userId={props.userId}
        onNavigateToLanding={props.onNavigateToLanding}
        personalData={props.personalData}
        onHandleNavigateToProfileScreen={props.onHandleNavigateToProfileScreen}
        onLogout={props.onLogout}
      />
      <div className="faq_page_section main-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-heading">Frequently Asked Questions</div>
            </div>
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">What is Fikrfree?</div>
                <div className="faq_text">
                  FikrFree is an insurance and healthcare marketplace app
                  offering a variety of insurance products and healthcare
                  services, including e-pharmacy and doctor consultations. You
                  can activate, deactivate, and manage your policies
                  conveniently through the app
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Where can I download the FikrFree app?
                </div>
                <div className="faq_text">
                  You can download the FikrFree app from the Google Play Store
                  and the Apple App Store.
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">Does FikrFree have a website?</div>
                <div className="faq_text">
                  Yes, you can visit our website at{" "}
                  <a href="https://fikrfree.com.pk/home">
                    fikrfree.com.pk
                  </a>{" "}
                  or{" "}
                  <a href="https://jazz.com.pk/mobile-apps/fikrfree" target="_blank">
                    Jazz FikrFree
                  </a>{" "}
                  for more information about our services and products.
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q4: Which types of insurance are available on FikrFree?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">
                    FikrFree offers a wide range of insurance products,
                    including:
                  </div>
                  <ul>
                    <li>Health Insurance</li>
                    <li>Life Insurance</li>
                    <li>Accidental Insurance</li>
                    <li>OPD and IPD Plans</li>
                    <li>Cancer Takaful Plan</li>
                    <li>Women Takaful Plan</li>
                    <li>Handset Insurance</li>
                    <li>Car Insurance</li>
                    <li>Bike Insurance</li>
                    <li>Home Content Insurance</li>
                    <li>Crop Insurance</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q5: Who are the partners on FikrFree?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">Our trusted partners include:</div>
                  <ul>
                    <li>BIMA</li>
                    <li>EFU</li>
                    <li>Waada</li>
                    <li>Webdoc</li>
                    <li>MedIQ</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q Num_list */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q6: How can I activate an insurance policy?
                </div>
                <div className="faq_list_content num_list">
                  <div className="faq_text">To activate a policy:</div>
                  <ul>
                    <li>Download and open the FikrFree app.</li>
                    <li>Log in using your phone number.</li>
                    <li>Enter your details for login.</li>
                    <li>Select your desired policy type from the homepage.</li>
                    <li>Choose an insurance partner and a price plan.</li>
                    <li>Select your preferred payment method.</li>
                    <li>Enter the OTP to verify.</li>
                    <li>Receive a confirmation SMS.</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q Num_list */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q7: Can I deactivate a policy?
                </div>
                <div className="faq_list_content num_list">
                  <div className="faq_text">
                    Yes, you can deactivate your policy. Follow these steps:
                  </div>
                  <ul>
                    <li>Open the FikrFree app.</li>
                    <li>Go to the "Policy" section.</li>
                    <li>
                      Click the "Deactivate" button for the desired policy.
                    </li>
                    <li>Receive a confirmation SMS.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q8: How can I sync my existing policy with the app?
                </div>
                <div className="faq_text">
                  If you are an existing policyholder, your policy details will
                  be automatically synced once you download and log in to the
                  FikrFree app.
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q9: What are the payment methods available?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">
                    You can pay for your insurance using:
                  </div>
                  <ul>
                    <li>Prepaid mobile balance (daily payments)</li>
                    <li>JazzCash wallet (monthly payments)</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q Num_list */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">Q10: How do I make a claim?</div>
                <div className="faq_list_content num_list">
                  <div className="faq_text">To make a claim:</div>
                  <ul>
                    <li>Open the FikrFree app..</li>
                    <li>Go to the "Claim" section.</li>
                    <li>Click "Make a Claim."</li>
                    <li>
                      Select the policy for which you want to file a claim.
                    </li>
                    <li>
                      Check the required documents under the dropdown menu.
                    </li>
                    <li>Upload the required documents.</li>
                    <li>Provide account information for claim distribution.</li>
                    <li>Click "Submit."</li>
                    <li>
                      Track your claim status in the "Claim" section of the
                      dashboard.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q11: How do I contact customer support?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">
                    For insurance-related claims, you can:
                  </div>
                  <ul>
                    <li>
                      Use the customer support feature in the FikrFree app.
                    </li>
                    <li>Call the helplines of our partners:</li>
                  </ul>
                  <ul className="circle_list">
                    <li>
                      <b>BIMA:</b> 042 111 71 9878
                    </li>
                    <li>
                      <b>EFU:</b> 042 3333 0333 or 042 111 333 033
                    </li>
                    <li>
                      {" "}
                      <b>Webdoc:</b> 051-8848801
                    </li>
                    <li>
                      <b>Waada:</b> 021-111-992-232
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q14: What healthcare services are available on FikrFree?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">FikrFree offers:</div>
                  <ul>
                    <li>
                      Doctor consultations (General Physicians and Specialists
                      in 30+ categories)
                    </li>
                    <li>
                      E-pharmacy for prescription and over-the-counter medicines
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q Num_list */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q15: How do I book a doctor’s appointment?
                </div>
                <div className="faq_list_content num_list">
                  <div className="faq_text">To make a claim:</div>
                  <ul>
                    <li>Open the FikrFree app</li>
                    <li>Click on "Talk to a Doctor."</li>
                    <li>Select a category (GP or Specialist).</li>
                    <li>Choose a doctor.</li>
                    <li>Select the date and time.</li>
                    <li>Proceed to payment.</li>
                    <li>Confirm the booking.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q16: What are the consultation charges?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>GP consultations range between PKR 500-1,500.</li>
                    <li>
                      Specialist consultations range between PKR 1,000-3,000.
                    </li>
                    <li>
                      MedIQ subscribers enjoy free GP consultations and 50% off
                      on specialist consultations.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q Num_list */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q17: How does the e-pharmacy service work?
                </div>
                <div className="faq_list_content num_list">
                  <div className="faq_text">To buy medicines:</div>
                  <ul>
                    <li>Open the FikrFree app.</li>
                    <li>Click on "Pharmacy."</li>
                    <li>Select a category and the desired medicine.</li>
                    <li>Enter your address.</li>
                    <li>Proceed to payment.</li>
                    <li>Track your order under the "Orders" tab.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q18: What discounts are available for MedIQ subscribers?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">MedIQ subscribers receive:</div>
                  <ul>
                    <li>10% discount on medicines</li>
                    <li>Free home delivery</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q19: How can I stay updated with FikrFree?
                </div>
                <div className="faq_list_content">
                  <div className="faq_text">
                    Follow us on social media for the latest updates:
                  </div>
                  <ul>
                    <li>
                      <b>Facebook:</b>fikrfreeofficialpk
                    </li>
                    <li>
                      <b>Instagram:</b> fikrfreeofficialpk
                    </li>
                    <li>
                      <b>X (Twitter):</b> fikrfreepk
                    </li>
                    <li>
                      <b>YouTube:</b> fikrfreeofficialpk
                    </li>
                    <li>
                      <b>Threads:</b> fikrfreeofficialpk
                    </li>
                    <li>
                      <b>Snapchat:</b> fikrfreepk
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q20: What if I have pricing-related concerns?
                </div>
                <div className="faq_text">
                  For pricing details, visit the FikrFree app or website at{" "}
                  <a href="https://fikrfree.com.pk/home">
                    fikrfree.com.pk
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q21: In which cities does FikrFree deliver medicines?
                </div>
                <div className="faq_text">
                  FikrFree delivers medicines across 40+ cities in Pakistan.
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q22: What is the cancellation or refund policy?
                </div>
                <div className="faq_text">
                  Refer to the FikrFree app for detailed terms and conditions
                  regarding cancellations and refunds.
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q23: Are all insurance products conventional or Takaful-based?
                </div>
                <div className="faq_text">
                  FikrFree offers both conventional and Takaful insurance
                  products to cater to diverse needs.
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  Q24: How can I provide feedback or suggestions?
                </div>
                <div className="faq_text">
                  You can provide feedback through the "Contact Us" section in
                  the app or via email at info@fikrfree.com.pk
                </div>
              </div>
            </div>

            {/* Document-Req-For-Claim */}
            <div className="col-lg-12">
              <div className="faq_content">
                <div className="faq_heading">
                  What documents are required for a claim?
                </div>
                <div className="faq_text">
                  A: The required documents vary depending on the type of
                  insurance. Below is a categorized breakdown of the documents
                  needed:
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q1: What documents are required for a health insurance claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC (Computerized National Identity Card).</li>
                    <li>
                      Final hospital invoice or discharge report stating the
                      admission and discharge dates.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q2: What documents are required for a life insurance claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC copies of both the claimant and the deceased.</li>
                    <li>Hospital death certificate and all medical records.</li>
                    <li>NADRA/Union Council death certificate</li>
                    <li>
                      Any additional documents requested by the Takaful Operator
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q3: What documents are required for a disability claim in
                  Accidental Insurance?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>Claimant’s Statement Form.</li>
                    <li>Attending Physician’s Report.</li>
                    <li>
                      Police and medico-legal report (for hospitalization due to
                      an accident or violence).
                    </li>
                    <li>CNIC copy of the claimant</li>
                    <li>Any additional documents deemed necessary</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q4: What documents are required for a death claim under
                  accidental insurance?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>All documents required for disability claims.</li>
                    <li>CNIC copies of both the claimant and the deceased.</li>
                    <li>Hospital death certificate and all medical records.</li>
                    <li>NADRA/Union Council death certificate.</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q5: What documents are required for a personal accident claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>Claimant’s Statement Form.</li>
                    <li>Hospital death certificate and all medical records</li>
                    <li>NADRA/Union Council death certificate</li>
                    <li>CNIC copies of both the claimant and the deceased.</li>
                    <li>
                      FIR/Police report and post-mortem report (if applicable).
                    </li>
                    <li>Any additional documents deemed necessary</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q6: What documents are required for a handset theft claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>FIR</li>
                    <li>CNIC number linked to the handset registration</li>
                    <li>Blocked IMEI report from PTA</li>
                    <li>
                      Incident statement (phone usage history and details)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q7: What documents are required for a screen damage claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC linked to the handset registration</li>
                    <li>Video showing the screen damage and IMEI number.</li>
                    <li>
                      Phone submission at a Waada dealership or collection by a
                      representative.
                    </li>
                    <li>Picture or proof of phone damage</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q8: What documents are required for a car insurance claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC copies (both sides)</li>
                    <li>Registration documents of the car.</li>
                    <li>Driving license of the insured.</li>
                    <li>Repair bills or cash memos.</li>
                    <li>Police report (for theft, robbery, or burglary)</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q9: What documents are required for a bike insurance claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC copies (both sides)</li>
                    <li>Registration documents of the bike</li>
                    <li>Driving license of the insured.</li>
                    <li>Repair bills or cash memos.</li>
                    <li>Police report (for theft, robbery, or burglary)</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q10: What documents are required for a crop insurance claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC copies (both sides).</li>
                    <li>
                      Pictures of the damaged crop with the insured in the frame
                    </li>
                    <li>
                      Certificates from the Meteorological Department, NDMA, or
                      the local Agricultural Department
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q11: What documents are required for a home content insurance
                  claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>CNIC copies (both sides).</li>
                    <li>Cell number of the insured.</li>
                    <li>Police report (for theft, robbery, or burglary).</li>
                    <li>Damaged items handed over to the company as salvage</li>
                    <li>Purchase invoices for damaged or stolen items</li>
                    <li>Any other necessary documents as required</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q12: What documents are required for a cancer takaful plan
                  claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>
                      Detailed medical reports from the attending physician or
                      oncologist.
                    </li>
                    <li>Pathology and diagnostic reports.</li>
                    <li>Claimant Statement Form.</li>
                    <li>CNIC copy of the claimant.</li>
                    <li>
                      Any additional documents requested by the Takaful Operator
                      .{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* List-Q */}
            <div className="col-lg-12">
              <div className="faq_content f_list">
                <div className="faq_heading">
                  Q13: What documents are required for a women takaful plan
                  claim?
                </div>
                <div className="faq_list_content">
                  <ul>
                    <li>Diagnostic Report / Doctor's Consultation Report</li>
                    <li>Duly filled Medical Claim Form</li>
                    <li>CNIC of the Member</li>
                    <li>Medical Bills .</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      
    </>
  );
};

export default FaqPage;
