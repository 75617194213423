import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/UserVerificationPopUp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// Rename insurranceProvider to InsuranceProvider

interface UserVerificationPopUpProps {
  isUserVerificationModalVisible: boolean;
  onHandleCloseUserVerificationModal: () => void;
  msisdn: string;
  isOTPSent: boolean;
  handleMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnPressSendOTPButton: () => void;
  handleOTPSubmit: () => void;
  otp: string[];
  inputRefs: any;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  showResendButton: boolean;
  timer: number;
  isLoading: boolean;
  errorMsg: string;
}

const UserVerificationPopUp = (props: UserVerificationPopUpProps) => {
  return (
    <>
      <Modal
        show={props.isUserVerificationModalVisible}
        onHide={props.onHandleCloseUserVerificationModal}
        className="verfication-modal"
        // size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="verification-modal-content">
            <div className="m-heading">Enter Mobile Number</div>
            <div className="m-text">Get started by signing Up / Logging In</div>
            <div className="verification-number">
              <span>+92</span>
              <input
                type="text"
                value={props.msisdn}
                onChange={props.handleMsisdnChange}
                maxLength={10}
                placeholder="Enter your mobile number"
              />
              {props.isOTPSent || props.isLoading ? null : ( // /> //   className="arrow-head-down" //   icon={faChevronRight} // <FontAwesomeIcon
                <FontAwesomeIcon
                  onClick={props.handleOnPressSendOTPButton}
                  icon={faChevronRight as IconProp}
                  className="arrow-head-down"
                />
              )}
            </div>
            {props.isLoading && !props.isOTPSent && (
              <p style={{ textAlign: "center" }}>Sending OTP</p>
            )}
            {props.isOTPSent && (
              <>
                <div className="m-heading">OTP Verification</div>
                <div className="v-otp-input">
                  {props.otp.map((value, index) => (
                    <input
                      key={index}
                      ref={(el) => (props.inputRefs.current[index] = el)}
                      type="tel"
                      name="one"
                      maxLength={1}
                      value={value}
                      onChange={(e) => props.handlePINInputChange(index, e)}
                      onKeyDown={(e) => props.handlePINInput(index, e)}
                    />
                  ))}
                </div>
                {/* Resend OTP {""} */}
                {props.showResendButton ? (
                  <div className="resend-otp">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={props.handleOnPressSendOTPButton}
                    >
                      Resend
                    </span>
                  </div>
                ) : (
                  <div className="resend-otp">
                    <span>
                      00:{props.timer < 10 ? `0${props.timer}` : props.timer}
                    </span>
                  </div>
                )}
                {/* <div className="resend-otp">
                  <span>
                    00:{props.timer < 10 ? `0${props.timer}` : props.timer}
                  </span>
                </div> */}
                {props.isLoading ? (
                  <div className="d-btn">
                    <button>Verifying</button>
                  </div>
                ) : (
                  <div className="d-btn">
                    <button onClick={props.handleOTPSubmit}>Verify</button>
                  </div>
                )}

                <div className="term-conditon">
                  By Signing in, I agree to
                  <Link to="/terms" target="_blank">
                    Terms & Conditions
                  </Link>
                  and
                  <Link to="/privacypolicy" target="_blank">
                    Privacy Policy
                  </Link>
                </div>
              </>
            )}
          </div>
          {props.errorMsg && <p className="error-msg">{props.errorMsg}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserVerificationPopUp;
