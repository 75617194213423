import React from "react";
import logo from "../../Assets/imgs/logo.webp";
import facebook from "../../Assets/imgs/fb-icon.webp";
import insta from "../../Assets/imgs/insta-icon.webp";
import { HashLink } from "react-router-hash-link";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="footer-section main-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-6">
            <HashLink
              to="/"
              className="footlogo"
              scroll={(el: any) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                })
              }
            >
              <img src={logo} alt="logo" width={80} height={55} />
            </HashLink>

            <div className="foot-desc">
              FikrFree Tailored Protection,
              <br /> Seamless Experience
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="footheading">Our services</div>
            <div className="col-row">
              <div className="foot-col">
                <div className="footlink">
                  <HashLink
                    to="/#insurance"
                    scroll={(el: any) =>
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      })
                    }
                  >
                    Insurance
                  </HashLink>
                </div>
                <div className="footlink">
                  <HashLink
                    to="/#claims"
                    scroll={(el: any) =>
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      })
                    }
                  >
                    Claims
                  </HashLink>
                </div>
              </div>
              <div className="foot-col" id="download">
                <div className="footlink">
                  <HashLink
                    to="/#faqs"
                    scroll={(el: any) =>
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      })
                    }
                  >
                    FAQs
                  </HashLink>
                </div>
                <div className="footlink">
                  <HashLink
                    to="/#download"
                    scroll={(el: any) =>
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      })
                    }
                  >
                    Download Now
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12  mob-flex">
            <div className="social-wraper">
              <div className="footheading">Socials</div>
              <div className="foot-social-icon">
                <a href="https://www.facebook.com/FikerFree" target="_blank">
                  <img src={facebook} alt="facebook" width={50} height={49} />
                </a>
                <a href="https://www.instagram.com/fikr.free/" target="_blank">
                  <img src={insta} alt="insta" width={50} height={48} />
                </a>
              </div>
            </div>
            <div className="social-wraper pd-left">
              <div className="footheading">Contact Us</div>
              <div className="foot-social-icon">
                <a
                  href="mailto:info@fikrfree.com.pk"
                  target="_blank"
                  style={{ marginBottom: "10px" }}
                >
                  info@fikrfree.com.pk
                </a>
              </div>
              <div
                className="foot-social-icon foot-desc"
                style={{ marginBottom: "10px" }}
              >
                Helpline 111
              </div>
              <div className="foot-social-icon foot-desc">
                Address: Jazz DHQ-1, Headquarters, 1-A Kohistan Rd, F-8 Markaz,
                Islamabad, 44000
              </div>
            </div>
          </div>
          <div className="copyright">
            © 2024 FikrFree. All rights reserved.
            <br />
            <span>
              <a href="/terms"> Terms & Conditions</a>{" "}
            </span>
            <span className="dot"></span>
            <span>
              <a href="/privacypolicy">Privacy Policy</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
