import React, { useEffect, useState } from "react";
import Aos from "aos";
import logo from "../../Assets/imgs/logo.webp";
import { Dropdown } from "react-bootstrap";
import closebtn from "../../Assets/imgs/cross-icon.webp";
import { imageUrl } from "../../utils/Config";
import userAvatar from "../../Assets/imgs/user-avatar.webp";
import profileVector from "../../Assets/imgs/profileVector.webp";
import logouticon from "../../Assets/imgs/logouticon.webp";
import menubar from "../../Assets/imgs/menubar.webp";
import { HashLink } from "react-router-hash-link";

type Props = {
  userId: any;
  onNavigateToLanding: () => void;
  personalData: any;
  onHandleNavigateToProfileScreen: () => void;
  onLogout: () => void;
};

const Header = (props: Props) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50; // Adjust the scroll threshold as needed
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);
    // AOS
    Aos.init();
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  return (
    <header className={`header-sec ${scrolled ? "navbar-scrolled" : ""}`}>
      <div className="container">
        <div className="header-wraper">
          <HashLink
            to="/"
            className="logo"
            scroll={(el: any) =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "end",
              })
            }
          >
            <img src={logo} alt="logo" width={80} height={55} />
          </HashLink>

          <div className={`nav-btn-flex ${isMobileMenuOpen ? "active" : ""}`}>
            <ul className="navList">
              <div className="close-flex dis-mobile">
                <div className="mob-logo">
                  <img src={logo} alt="logo" width={113} height={77} />
                </div>
                <div className="close-btn" onClick={toggleMobileMenu}>
                  <img src={closebtn} alt="close" width={15} height={15} />
                </div>
              </div>

              <li>
                <HashLink
                  to="/#"
                  onClick={toggleMobileMenu}
                  scroll={(el: any) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#insurance"
                  onClick={toggleMobileMenu}
                  scroll={(el: any) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }
                >
                  Insurance
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#claims"
                  onClick={toggleMobileMenu}
                  scroll={(el: any) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }
                >
                  Claims
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#faqs"
                  onClick={toggleMobileMenu}
                  scroll={(el: any) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }
                >
                  FAQs
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#download"
                  onClick={toggleMobileMenu}
                  scroll={(el: any) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }
                >
                  Download
                </HashLink>
              </li>

              {!props.userId ? (
                <div className="d-btn dis-desk">
                  <button onClick={props.onNavigateToLanding}>Login</button>
                </div>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    className="headerDropdownbtn"
                    id="dropdown-basic"
                  >
                    <img
                      src={
                        props.personalData &&
                        props.personalData.profile_pic_url &&
                        props.personalData.profile_pic_url.length > 0
                          ? imageUrl + props.personalData.profile_pic_url
                          : userAvatar
                      }
                      // src={userAvatar}
                      alt="user"
                      className="user__avatar"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdownmenu">
                    <Dropdown.Item
                      href="#"
                      onClick={props.onHandleNavigateToProfileScreen}
                    >
                      <img src={profileVector} alt="icon" />
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" onClick={props.onLogout}>
                      <img src={logouticon} alt="icon" />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {/* {/ profile drop down /} */}

              {/* {/ profile drop down /} */}
            </ul>
            {/* <div className="d-btn dis-desk">
          <button>SUBSCRIBE</button>
        </div> */}
          </div>
          <div className="menubar dis-mobile" onClick={toggleMobileMenu}>
            <img src={menubar} alt="menubar" width={24} height={19} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
